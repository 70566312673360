import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import HeroHome from "../components/Hero/HeroHome"
import FlexibleContentStandard from "../components/FlexibleContentStandard/FlexibleContentStandard"

const IndexPage = ({ data }) => {
  const {
    metaTitle,
    metaDescription,
    heading,
    headingTranslated,
    image,
    ctaLink,
    ctaText,
    ctaTextTranslated,
    flexibleContentStandard,
  } = data.craftHomeHomeEntry

  const ctaData = {
    link: ctaLink,
    text: ctaText,
    textTranslated: ctaTextTranslated,
  }

  return (
    <Layout>
      <Seo title={metaTitle || heading} description={metaDescription} />

      <HeroHome
        heading={heading}
        headingTranslated={headingTranslated}
        image={image}
        cta={ctaData}
      />

      {flexibleContentStandard &&
        flexibleContentStandard.map((item, index) => {
          const isFirst = false
          const isLast = index === flexibleContentStandard.length - 1
          const hasSamePrevBgColour =
            flexibleContentStandard[index - 1]?.backgroundColour ===
            item.backgroundColour
          const hasSameNextBgColour =
            flexibleContentStandard[index + 1]?.backgroundColour ===
            item.backgroundColour

          return (
            <FlexibleContentStandard
              key={item.id}
              isFirst={isFirst}
              isLast={isLast}
              hasSamePrevBgColour={hasSamePrevBgColour}
              hasSameNextBgColour={hasSameNextBgColour}
              {...item}
            />
          )
        })}
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    craftHomeHomeEntry {
      metaTitle
      metaDescription
      title
      heading
      headingTranslated: textPrimary
      image {
        ...imageFragment
      }
      ctaLink: buttonLink {
        uri
      }
      ctaText: textSecondary
      ctaTextTranslated: translatedTitle
      flexibleContentStandard {
        ...flexibleContentStandardFragment
      }
    }
  }
`
