import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const HeroHome = ({ heading, headingTranslated, image, cta }) => {
  return (
    <div className="relative">
      <div className="w-full h-full absolute inset-0">
        <GatsbyImage
          className="w-full h-full"
          image={getImage(image[0].localFile)}
          alt={image[0].title}
        />
      </div>
      <div className="container relative text-white">
        <div className="py-20 md:py-36">
          <div className="lg:w-1/2">
            <h1 className="text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-semibold">
              {heading}
            </h1>
            {headingTranslated && (
              <h2 className="text-3xl opacity-60 md:text-4xl lg:text-5xl xl:text-6xl">
                {headingTranslated}
              </h2>
            )}
          </div>
        </div>
        {cta.link && cta.text && (
          <Link
            className="inline-block -ml-ocs py-6 pr-9 md:pr-52 pl-ocs bg-green rounded-tr-xl relative text-lg font-bold uppercase transition hover:bg-opacity-75"
            to={`${cta.link[0].uri}/`}
          >
            {cta.text}
            {cta.textTranslated && (
              <div className="font-normal opacity-75">{cta.textTranslated}</div>
            )}
          </Link>
        )}
      </div>
    </div>
  )
}

HeroHome.propTypes = {
  heading: PropTypes.string.isRequired,
  headingTranslated: PropTypes.string,
}

export default HeroHome
